import {graphql} from 'gatsby';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from 'components/layout';

export default ({data}) => {

    const {body} = data.mdx;


    return (
        <Layout isVisibleBack={true}>
            <MDXRenderer>{body}</MDXRenderer>
        </Layout>
    );
};

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
      }
    }
  }
`;